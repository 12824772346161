import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  Redirect,
} from "react-router-dom";
import Home from "../pages/Home/Home";
import SignIn from "../pages/Auth/SignIn";
import Layout from "../pages/Layout/Layout";
import Tournament from "../pages/Tournament/Tournament";
import AdminLogin from "../Component/adminlogin";
import AdminProfile from "../Component/adminprofile";
import AdminSignup from "../Component/adminsignup";
import ProtectedRoute from "../Component/Protectedroute";
import Profile from "../pages/Profile/Profile";
import Winners from "../pages/Winners/Winners";
import Wallet from "../Component/wallet/wallet";
import PublicRoute from "../Component/PublicRoute";
import Lichessauth from "../Context/Lichessauth";
import UserDetails from "../Component/Authentication/UserDetails";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="home" element={<Navigate to="/" replace />} />
      {/* <Route path="login" element={<SignIn />} /> */}
      <Route path="login/register" element={<Navigate to="/login" replace />} />
      <Route path="my" element={<Navigate to="/profile" replace />} />
      <Route path="/tournaments" element={<Tournament />} />
      <Route path="/winners" element={<Winners />} />
      <Route path="/adminlogin" element={<AdminLogin />} />
      <Route path="/signin" element={<SignIn />} />
      <Route
        path="/adminprofile"
        element={
          <ProtectedRoute redirectPath="/adminlogin" >
            <AdminProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute redirectPath="/">
            <Profile />
           </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute redirectPath="/" >
            <SignIn />
          </PublicRoute>
        }
      />
      <Route path="adminsignup" element={<AdminSignup />} />
      <Route path="wallet" element={<Wallet />} />
      <Route path="lichessauth" element={<Lichessauth/>} />
    </Route>
  )
);

export default router;
