import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import NavBar from "../../Component/NavBar/NavBar";
import userImage from "../../assets/images/Profile/profile.jpg";
import './Profile.css';
import flag from '../../assets/images/Profile/IN.png';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { AppContext } from "../../Context/Context";
import { Loader2 } from "lucide-react";
import { updateProfilePicture } from "../../utils/functions/updateProfilePicture";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../Component/Footer/Footer";

const Profile = () => {
  const { getUser, idtokencontex } = useContext(AppContext);
  const { userData } = useContext(AppContext);

  const [state, setState] = useState({
    firetoken: "",
    country: '',
    state: '',
    userDetails: {
      fname: "",
      lname: "",
      email: "",
      lichessId: "",
      line: "",
      city: "",
      pincode: "",
      mobile: "",
      dob: "",
      gender: "",
      bio: "",
      country: "",
      state: ""
    },
    previewUrl: null,
    isUploading: false
  });

  useEffect(() => {
    if (userData && userData.name) {
      const name1 = userData.name.split(" ");
      setState(prevState => ({
        ...prevState,
        country: userData.country || "",
        state: userData.state || "",
        userDetails: {
          fname: name1[0] || "",
          lname: name1[1] || "",
          bio: userData.bio || "",
          email: userData.email || "",
          lichessId: userData.lichessId || "",
          line: userData.line || "",
          city: userData.city || "",
          dob: userData.dob || "",
          pincode: userData.pincode || "",
          gender: userData.gender || "",
          mobile: userData.mobile || "",
          country: userData.country || "",
          state: userData.state || ""
        }
      }));
    }
  }, [userData]);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      return;
    }

    const fetchUserData = async () => {
      try {
        const idToken = await user.getIdToken(true);
        setState(prevState => ({ ...prevState, firetoken: idToken }));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [idtokencontex]);

  const onProfileValueChange = useCallback((event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, [name]: value }
    }));
  }, []);

  const handlePhotoChange = useCallback(async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setState(prevState => ({ ...prevState, isUploading: true }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setState(prevState => ({ ...prevState, previewUrl: reader.result }));
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('profilePicture', userData?.user?.profilePicture || '');

    try {
      const response = await updateProfilePicture(formData);
      if (response.status === 200) {
        toast.success('Profile picture updated successfully!');
        if (idtokencontex) {
          const updatedUser = await getUser(idtokencontex);
          // Handle updated user if needed
        }
      }
    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast.error('Failed to update profile picture. Please try again.');
      setState(prevState => ({ ...prevState, previewUrl: null }));
    } finally {
      setState(prevState => ({ ...prevState, isUploading: false }));
    }
  }, [userData?.user?.profilePicture, getUser, idtokencontex]);

  const handleProfileEdit = useCallback(async (event) => {
    event.preventDefault();

    const updatedUserDetails = {
      ...state.userDetails,
      country: state.country,
      state: state.state
    };

    try {
      await axios.put('https://us-central1-chesstycoon.cloudfunctions.net/app/api/v1/updateUser', updatedUserDetails, {
        headers: {
          'Authorization': `Bearer ${state.firetoken}`,
          'Content-Type': 'application/json'
        }
      });
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  }, [state]);

  const memoizedCountryDropdown = useMemo(() => (
    <CountryDropdown
      value={state.country}
      onChange={(val) => setState(prevState => ({
        ...prevState,
        country: val,
        userDetails: { ...prevState.userDetails, country: val }
      }))}
    />
  ), [state.country]);

  const memoizedRegionDropdown = useMemo(() => (
    <RegionDropdown
      country={state.country}
      value={state.state}
      onChange={(val) => setState(prevState => ({
        ...prevState,
        state: val,
        userDetails: { ...prevState.userDetails, state: val }
      }))}
    />
  ), [state.country, state.state]);

  return (
    <>
      <NavBar />
      <form className="profileForm" onSubmit={handleProfileEdit}>
        <div className="editProfile">
          <button type="submit" className="editButton">Edit Profile</button>
        </div>
        <div className="userNameDetails">
          <div className="userNameImage">
            <div className="imageContainer">
              {state.isUploading ? (
                <div className="loaderOverlay">
                  <Loader2 className="animate-spin" />
                </div>
              ) : (
                <img className="userImage" src={state.previewUrl || userImage} alt="userImage" />
              )}
            </div>
            <div className="userName">
              <p>CTC Id</p>
              <p>{userData?.ctcId || ''}</p>
            </div>
          </div>
          {/* <div className="photo-uploader">
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              id="photo-upload"
              style={{ display: 'none' }}
            />
            <label htmlFor="photo-upload" className="upload-button">
              Upload Photo
            </label>
          </div> */}
        </div>
        <div className="formFields">
          <div className="leftForm">
            <label>
              First Name
              <input
                required
                value={state.userDetails.fname}
                onChange={onProfileValueChange}
                name="fname"
                type="text"
                placeholder="First Name"
              />
            </label>
            <label>
              Last Name
              <input
                required
                value={state.userDetails.lname}
                onChange={onProfileValueChange}
                name="lname"
                type="text"
                placeholder="Last Name"
              />
            </label>
            <label>
              Email
              <input
                required
                value={state.userDetails.email}
                disabled={true}
                onChange={onProfileValueChange}
                name="email"
                type="email"
                placeholder="Email"
              />
            </label>
            <label>
              Lichess ID
              <input
                required
                value={state.userDetails.lichessId}
                onChange={onProfileValueChange}
                name="lichessId"
                disabled={true}
                type="text"
                placeholder="Lichess ID"
              />
            </label>
            <label className="address">
              Address
              <input
                required
                value={state.userDetails.line}
                onChange={onProfileValueChange}
                name="line"
                type="text"
                placeholder="Address Line"
              />
              <input
                required
                value={state.userDetails.city}
                onChange={onProfileValueChange}
                name="city"
                type="text"
                placeholder="City"
              />
              <input
                required
                value={state.userDetails.pincode}
                onChange={onProfileValueChange}
                name="pincode"
                type="text"
                placeholder="Pincode"
              />
              {memoizedCountryDropdown}
              {memoizedRegionDropdown}
            </label>
          </div>
          <div className="rightForm">
            <label>
              Mobile Number
              <img className="flag" src={flag} alt="IndianFlag" />
              <input
                required
                value={state.userDetails.mobile}
                onChange={onProfileValueChange}
                name="mobile"
                type="tel"
                placeholder="Mobile Number"
              />
            </label>
            <label htmlFor="dob">
              Date of Birth:
              <input
                type="date"
                id="dob"
                name="dob"
                value={state.userDetails.dob}
                onChange={onProfileValueChange}
                required
              />
            </label>
            <label htmlFor="gender">
              Gender:
              <select
                id="gender"
                name="gender"
                value={state.userDetails.gender}
                onChange={onProfileValueChange}
                required
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </label>
            <label htmlFor="bio">
              Bio:
              <textarea
                id="bio"
                name="bio"
                value={state.userDetails.bio}
                onChange={onProfileValueChange}
                rows={4}
              />
            </label>
          </div>
        </div>
      </form>
      <Footer/>
      <ToastContainer />
    </>
  );
};

export default Profile;