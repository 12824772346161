import React, {useState , useContext} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
// import { auth } from '../utils/firebase';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import styles from '../assets/styles/adminsignin.module.css';
import backgroundImage from '../assets/images/b13b55baf46a2e6488bc3b0f3542c3a9.png'; 
import { auth } from '../utils/firebase/Firebase';
// import { auth } from '../utils/firebase';
import { AppContext } from "../Context/Context";
import logo from '../assets/images/navbar/CTcLogo1.png'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
function AdminLogin() {
  const { isSigned, setSignIn } =  useContext(AppContext);
  const navigation = useNavigate()
  const [email ,setemail] = useState("")
  const [ password , setpassword] = useState("")
  const [showPassword , setShowPassword] = useState(false);
  const Submit = async (e) =>{
    e.preventDefault()
    await signInWithEmailAndPassword(auth , email , password)
    .then((userCredential)=>{
      const user = userCredential.user

      console.log(user)
      // assigen issigned in true
      setSignIn(true) 

      navigation("/adminprofile")
    }).catch((error)=>{
      console.log(error.code)
      console.log(error.message)
    })
  }
  
  return (
    
    <>
    
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className={styles.navb}><img className={styles.logo} src={logo} alt="logo" /> <div className={styles.logotext}>Chess Tycoon</div> </div>
      <form onSubmit={Submit} className={styles.form}>
        <h2>Login</h2>
       
        <input
        placeholder='Email'
          type="email"
          id="email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
          required
        />
        
{/*         
        <input
        placeholder='Password'
          type="password"
          id="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          required
        /> */}
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center',  width : "95%" ,marginBottom : "12px" }}>
      <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        placeholder='Password'
        value={password}
        onChange={(e) => setpassword(e.target.value)}
        required
        style={{ paddingRight: '40px', flex: 1  , margin : "0"}}
      />
      <div 
        onClick={() => setShowPassword(!showPassword)} 
        style={{ 
          position: 'absolute', 
          right: '10px', 
          cursor: 'pointer',
          height : "100%",
          display : "flex",
          justifyContent : "center",
          alignItems : "center"
        }}
      >
       {!showPassword ? <FaEye /> : <FaEyeSlash/>  } 
      </div>
    </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
     <Link className={styles.link} to="/adminsignup">Create an account</Link> 
      </form>

    </div>
    </>
  )
}

export default AdminLogin



