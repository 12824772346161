import axios from "axios";
import { getAuth } from "firebase/auth";

const url = `${process.env.REACT_APP_BACKEND}/api/v1/getUser`;

export const getUserDetailById = async (navigate) => {
  // console.log("getUserDetailsById");
  const auth = getAuth();
  try {
    const user = auth.currentUser;
    if (!user) {
      console.log("no user is signed in");
      return { status: 401, data: "No user is signed in" };
    }

    const idToken = await user.getIdToken(true);
    console.log("get req pehle ", idToken);
    const response = await axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + idToken,
        'Content-Type': 'application/json'
      }
    })
   
    
    if ( response.data.message === "userNotFound") {
      console.log("error in finding data");
      return {status: 404, data: "User not found"};
    } else if (response.status === 200) {
        // console.log("user found");
        return {status: 200, data: response.data.user};
    } else {
        console.log("some error occured");
        return {status: 500, data: "Some error occured"};
    }
    // return { status: 200, data: response.data.user };
  } catch (error) {
    console.error('Error:', error);
    console.error("An error occurred:", error.message);
    return { status: error.response ? error.response.status : 500, data: error.message };
  }
};