import { motion, useAnimation, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react'

const Reveal = ({children}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once:true});
    const mainControls = useAnimation();
    useEffect(()=>{
        // //(ref, isInView)
        if(isInView){
            mainControls.start('visible')
        }
    },[isInView, mainControls])
  return (
    <motion.div ref={ref} variants={{
        hidden:{opacity:0, y:75},
        visible:{opacity:1, y:0}
    }} 
    animate={mainControls}
    transition={{duration:0.5, delay:1, staggerChildren:.5}}
    initial="hidden" style={{margin:'auto', display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
        {children}
    </motion.div>
  )
}

export default Reveal