import React, { useState, useEffect, useCallback, useContext } from 'react';
import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce';
import { AppContext } from '../Context/Context';
import { useNavigate } from 'react-router-dom';
import { getUserDetailById } from '../utils/functions/getUserDetailById';
import './Lichessauth.css'; // Make sure to create this CSS file
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const lichessHost = 'https://lichess.org';
const scopes = ['email:read', 'tournament:write'];
const clientId = 'example.com'; // Replace with your actual client ID
const clientUrl = (() => {
  const url = new URL(window.location.href);
  url.search = '';
  return url.href;
})();

const oauth = new OAuth2AuthCodePKCE({
  authorizationUrl: `${lichessHost}/oauth`,
  tokenUrl: `${lichessHost}/api/token`,
  clientId,
  scopes,
  redirectUrl: clientUrl,
  onAccessTokenExpiry: refreshAccessToken => refreshAccessToken(),
  onInvalidGrant: _retry => { },
});

function Lichessauth() {
  const navigate = useNavigate();
  const { accessToken, setGoogleSignIn, setSignIn, setUserData, setLichessToken, setLichessId, lichessId, userData ,lichessToken ,samelichessid , setsamelichessid } = useContext(AppContext);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const secretKey = 'your-secret-key';

  // Encrypt data
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  // Decrypt data
  const decryptData = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const login = useCallback(async () => {
    try {
      setIsLoading(true);
      await oauth.fetchAuthorizationCode();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchEmail = useCallback(async (fetch) => {
    try {
      setIsLoading(true);
      const res = await fetch(`${lichessHost}/api/account/email`);
      const data = await res.json();
      setEmail(data.email);
      console.log(data, 'fetchemail');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchlicheesid = useCallback(async (fetch) => {
    try {
      setIsLoading(true);
      const res = await fetch(`${lichessHost}/api/account`);
      const data = await res.json();
      console.log(data, 'fetch id');
      setLichessId(data.id)
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    if (lichessToken) {
      try {
        await fetch(`${lichessHost}/api/token`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${lichessToken}`,
          },
        });
      } catch (err) {
        console.error('Error during logout:', err);
      }
    }
    setError(null);
    setEmail(null);
  }, [lichessToken]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const hasAuthCode = await oauth.isReturningFromAuthServer();
        if (hasAuthCode) {
          const accessContext = await oauth.getAccessToken();
          console.log(accessContext, 'access contect');
          const token = accessContext.token.value;
          setLichessToken(token);
          sessionStorage.setItem('lichessToken', token);
          const decoratedFetch = oauth.decorateFetchHTTPClient(window.fetch);
          // sessionStorage.setItem("" , oauth)
          await fetchEmail(decoratedFetch);
          await fetchlicheesid(decoratedFetch)
        } else {
          await login();
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, [setLichessToken, login]);

  const updateUserWithLichessId = async (userDetails, lichessId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken(true);
    const updatedUserDetails = {
      ...userDetails,
      lichessId,
    };
    console.log(updatedUserDetails)
    const url = `${process.env.REACT_APP_BACKEND}/api/v1/updateUser`;
    const headers = {
      'Authorization': 'Bearer ' + idToken,
      'Content-Type': 'application/json'
    };
    try {
      const response = await axios.put(
        url,
        updatedUserDetails,
        {
          headers
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update user with Lichess ID');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  useEffect(() => {
    const userDetails = async () => {
      setIsLoading(true);
      try {
        const details = await getUserDetailById(navigate);

        if (details.status === 200) {
          console.log(lichessId, details)
          if (lichessId && details.data.lichessId !== lichessId) {
            // User exists but Lichess ID is different or not set, update it
            try {
              const updatedUser = await updateUserWithLichessId(details.data, lichessId);
              console.log(updatedUser)
              setUserData(updatedUser);
              toast.success('Lichess ID updated successfully');
            } catch (updateError) {
              toast.error('Failed to update Lichess ID. Please try again.');
              setUserData(details.data); // Set original user data if update fails
            }
          } else {
            // User exists and Lichess ID is already set or not needed
            setUserData(details.data);
        const encryptedlichessid = encryptData(lichessId);
        sessionStorage.setItem("encryptedlichessid", encryptedlichessid)
          }
          setSignIn(true)
          navigate('/');


        } else if (details.status === 404) {
          setGoogleSignIn(true);
          navigate('/login');
        } else {
          throw new Error('Bad request. Please check your input.');
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);

      }
    };

    if (accessToken) {
      userDetails();
    }
  }, [accessToken, navigate, setSignIn, setUserData, setGoogleSignIn, lichessId]);

  // useEffect(() => {
  //   if (userData != null && userData.lichessId != null && lichessId != null) {
  //     if (userData.lichessId !== lichessId) {
  //       setSignIn(false)
  //       alert(`Current Lichess Account ${lichessId} doesnt match with your connected Lichess account with username ${userData.lichessId} `)
  //       window.location = `${process.env.REACT_APP_BASE_URL}/lichessauth`

  //     } else {
  //       setSignIn(true);
  //       const encryptedlichessid = encryptData(lichessId);
  //       sessionStorage.setItem("encryptedlichessid", encryptedlichessid)
  //       // setsamelichessid(true)
  //       navigate('/')
  //     }
  //   } else {
  //     setSignIn(false)
  //   }
  // }, [userData, lichessId])


  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="loading-screen">
      <div className="loader"></div>
    </div>
  );
}

export default Lichessauth;

