import { useState, useEffect, useContext } from "react";
import { getPercentageBarValue } from "../../utils/functions/getPercentageBarValue";
import { RxCross1 } from "react-icons/rx";
import { AppContext } from "../../Context/Context";
import axiosCTC from "../../axiosClientCTC";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import coinicon from '../../Component/wallet/wm-front.png'

export default function CardDetails({ setDetails, width, details }) {
  const { userData, accessToken, setUserData, lichessToken, setLichessToken } = useContext(AppContext);
  const [cardRightActiveSection, setCardRightActiveSection] = useState("prizeDistribution");
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [user, setUser] = useState(null);
  const [isJoining, setIsJoining] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('lichessToken');
    if (token) {
      setLichessToken(token);
    }

    if (userData) {
      setUser(userData);
    }
  }, [setLichessToken, userData]);

  useEffect(() => {
    if (user && details?.registrants) {
      const userRegistered = details.registrants.some(
        registrant => registrant.ctcId === user.ctcId || registrant.lichessId === user.lichessId
      );
      setIsUserRegistered(userRegistered);
    }
  }, [user, details?.registrants]);

  if (width > 760 && cardRightActiveSection === "rules") {
    setCardRightActiveSection("prizeDistribution");
  }

  const barValueStr = details?.status === "Completed"
    ? "100%"
    : getPercentageBarValue(details?.participantsLimit || 0, details?.registrants?.length || 0);

  const handleJoinTournament = async () => {
    if (!user) {
      toast.error("User data is not available. Please try again later.");
      return;
    }

    if (isUserRegistered) {
      toast.info("You are already registered for this tournament");
      return;
    }
    
    if (details?.registrants?.length === details?.participantsLimit) {
      toast.error("Participants Limit Reached, all seats filled!");
      return;
    }

    try {
      setIsJoining(true);
      await axiosCTC.post('/joinTournament', {
        tournamentId: details?.tournamentId,
        ctcId: user.ctcId,
        token: lichessToken
      }, {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      });
      toast.success("Successfully joined the tournament");
      setIsUserRegistered(true);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Unknown Error!');
    } finally {
      setIsJoining(false);
    }
  };

  return (
    <div className="detailsOfCard" style={{paddingTop: "0px"}}>
      <ToastContainer />
      <div className="back">
        <button onClick={() => setDetails(null)}>
          <RxCross1 />
        </button>
      </div>
      <div className="freq">{details?.tournamentName}</div>
      <div className="details">
        <div className="left">
          <div className="top">
            <span className="prizePool">
              <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                {details?.paymentType === 'CTC' ? <img src={coinicon} alt="CTC" /> : "₹"}
                {details?.prizePool}
              </div>
              <div>Prize Pool</div>
            </span>
            <span className="swiss">
              <div>{details?.tournamentMode}</div>
              <div>{details?.rounds} Rounds</div>
            </span>
          </div>

          <div className="progress">
            <div className="bar">
              <div className="value" style={{ width: barValueStr }}></div>
            </div>
            <div className="txt">
              <span className="spots-left">
                {details?.spotsLeft}{" "}
                {details?.status !== "Completed" && " Spots left"}
              </span>
              <span className="total-spots">
                {(details?.participantsLimit || 0) - (details?.registrants?.length || 0) || 100}
                {details?.status !== "Completed" ? " Spots" : " Participants"}
              </span>
            </div>
          </div>

          <div className="mid1">
            <span className="dateTimeBox">
              <div className="dateTime">
                <span className="date">
                  <div></div>
                  <div>{details?.tournamentDate}</div>
                </span>
                <span className="time">{details?.tournamentStartTime}</span>
              </div>
            </span>
            <span className="timeControlBox">
              <div className="controls">
                <div>
                  {details?.timeControl?.[0]}<span className="small">min</span>
                  +{details?.timeControl?.[1]}<span className="small">sec</span>
                </div>
              </div>
              <div className="timeControl">Time Control</div>
            </span>
          </div>

          <div className="mid2">
            <span className="ratingBox">
              <div className="rating">Open For All</div>
              <div className="txt">Rating Range</div>
            </span>
            <span className="ratedBox">
              <div className="rated">{details?.lichessRated}</div>
              <div className="txt">Rated?</div>
            </span>
          </div>

          <div className={`join ${details?.status !== "Upcoming" ? "pad-0" : ""}`}>
            {details?.status === "Upcoming" && (
              <div className="balance">
                <div className="leftt">
                  <span className="tag"></span>
                  <span className="val">
                    {user ? (details?.paymentType === "CTC" ? 
                      <div style={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }}>
                        Balance: <img style={{ height: "19px" }} src={coinicon} alt="" /><span> {user?.wallet?.ctc}</span>
                      </div> : 
                      <div>Balance:₹{user.wallet?.inr}</div>) : 
                    "Loading..."}
                  </span>
                </div>
                <div className="rightt">
                  <span className="tag"></span>
                  <span className="val">
                    <div style={{ display: "flex", gap: "2px" }}>
                      Required: {details?.paymentType === "CTC" ? <img style={{ height: '19px' }} src={coinicon} alt="CTC" /> : "INR"}
                      {details?.entryFee}
                    </div>
                  </span>
                </div>
              </div>
            )}
            {details?.status === "past" ? (
              <div className="btn">Play More</div>
            ) : (
              <div className="btn" onClick={handleJoinTournament}>
                {isJoining ? "Joining..." : isUserRegistered ? "Registered" : "Join Now"}
              </div>
            )}

            {details?.status === "past" && (
              <div className={`btn ${details?.status === "Completed" ? "redBg" : ""}`}>
                Completed
              </div>
            )}
          </div>

          <div className={`footer-carousel ${details?.status === "Completed" ? "marginTopNegative" : ""}`}>
            Top Prize: {details?.topPrize} CTc | Win Probability: {details?.winProbability} | Guaranteed Rewards
          </div>
        </div>

        <div className="right">
          <div className="btnBox">
            <span className={`${cardRightActiveSection === "leaderboard" ? "active" : ""}`}
              onClick={() => setCardRightActiveSection("leaderboard")}>
              Leaderboard
            </span>
            {width <= 760 && (
              <span className={`${cardRightActiveSection === "rules" ? "active" : ""}`}
                onClick={() => setCardRightActiveSection("rules")}>
                Rules
              </span>
            )}
          </div>
          {cardRightActiveSection !== "rules" && (
            <div className="rankings">
              <div className="row">
                <span>RANK #1</span>
                <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"5px"}}>
                  <img src={coinicon} alt="CTC" />700
                </span>
              </div>
              <div className="row">
                <span>RANK #2</span>
                <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"5px"}}>
                  <img src={coinicon} alt="CTC" />420
                </span>
              </div>
              <div className="row">
                <span>RANK #3</span>
                <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"5px"}}>
                  <img src={coinicon} alt="CTC" />280
                </span>
              </div>
              <div className="row">
                <span>RANK #4 to #10</span>
                <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"5px"}}>
                  <img src={coinicon} alt="CTC" />120
                </span>
              </div>
              <div className="row">
                <span>RANK #11 to #25</span>
                <span style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"5px"}}>
                  <img src={coinicon} alt="CTC" />37
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="rules">
        <div className="btnBox">
          <span className="active">Rules</span>
        </div>
      </div>

      {(width > 760 || cardRightActiveSection === "rules") && (
        <div className="rulesTxt">
          <div>
            In an Arena tournament, each competitor does not necessarily play all
            other entrants. Competitors meet one-on-one in each round and are
            paired using a set of rules designed to ensure that each competitor
            plays opponents with a similar running score, but not the same
            opponent more than once. The winner is the competitor with the
            highest aggregate points earned in all rounds. All competitors play
            in each round unless there is an odd number of players.
          </div>
          <ol>
            <li>
              A win has a base score of 1 point, a draw 1/2 point, and a loss is
              worth no points.
            </li>
            <li>
              All participants play the same number of games, and can only play
              each other once.
            </li>
            <li>
              Every player will play every round, and the player with the
              highest number of points at the end of the tournament is the
              winner.
            </li>
            <li>
              There is a countdown for your first move. Failing to make a move
              within this time will forfeit the game to your opponent.
            </li>
          </ol>
          <div>
            <h3>How is the winner decided?</h3>
            The player(s) with the most points at the conclusion of the
            tournament's set time limit will be announced winner(s).
          </div>

          <div>
            When two or more players have the same number of points, the
            tournament performance is the tie break.
          </div>
        </div>
      )}
    </div>
  );
}