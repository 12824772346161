import React from 'react'
import { useState,useEffect } from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import AdminNav from './AdminNav'
import { auth } from '../utils/firebase/Firebase'
function AdminProfile() {
    const navigation = useNavigate()
    const [email , setemail] = useState("")
    useEffect(()=>{
           onAuthStateChanged(auth , (user)=>{
            if(user){
                console.log(user.uid)
                setemail(user.email)
            }else{
                console.log("user is logged out")
            }
        })

    },[])
    const logout = ()=>{
        signOut(auth).then(()=>{
            console.log(logout)
            navigation("/adminlogin")
        }).catch((error)=>{
            console.log(error.message)
            console.log(error.status)
        })
    }
  return (
    
   <div style={{width : "100vw" , height : "100vh" , padding : "0", margin : "0" , backgroundColor : "green"}}>
    <AdminNav email={email}/>
    <div> {email}</div>
    <button onClick={logout}>Logout</button>
       
   </div>
  )
}

export default AdminProfile
