import React, { useContext } from "react";
import "./Footer.css";
import { AppContext } from "../../Context/Context";
import facebook from '../../assets/images/footer/facebook.png'
import linkedin from '../../assets/images/footer/linkedin.png'
import twitter from '../../assets/images/footer/twitter.png'
import instagram from '../../assets/images/footer/instagram.png'

const Footer = () => {
    const {open} = useContext(AppContext);
  return (
      <footer className={open ? 'open' : 'close'} >
      <div class="footer-content">
          {/* <a href="/about">About</a> */}
          {/* <span class="separator">|</span> */}
          <a href="https://drive.google.com/file/d/1lWELO16lwVeO6sciZPl9WP3CPycC1UOn/view?usp=sharing">Terms & Conditions</a>
          {/* <span class="separator">|</span> */}
          {/* <a href="/refund">Refund Policy</a> */}
          <span class="separator">|</span>
          <a href="https://drive.google.com/file/d/1nNNTNM6Wus5r-QcfR3p-HatnuTphKJoO/view?usp=sharing">Privacy Policy</a>
          <span class="separator">|</span>
          <span>Chess Tycoon © 2024</span>
          <span class="separator">|</span>
          <a href="https://www.zorwaytech.com" >Powered by Zorway Technologies LLP</a>
        </div>
        
        <div className="logos">
            {/* <a href="#"><img src={facebook} alt="facebook" /></a> */}
            <a href="https://x.com/chesstycoon_in"><img src={twitter} alt="twitter" /></a>
            <a href="https://www.instagram.com/chesstycoonofficial/"><img src={instagram} alt="linkedin" /></a>
            <a href="https://in.linkedin.com/showcase/chess-tycoon/"><img src={linkedin} alt="instagram" /></a>
        </div>
      </footer>
  );
};

export default Footer;
