  import React from 'react'
  import "./Skeleton.css"
  
  const SkeletonTournamentCard = ()=> {
    return (
      <div className="tournament-card skeleton">
        <div className="cardHead skeleton">
          <div className="skeleton-text short"></div>
          <div className="skeleton-text medium"></div>
          <div className="skeleton-text short"></div>
        </div>
        <div className="cardMid skeleton">
          <div className="top">
            <div className="skeleton-text medium"></div>
            <div className="skeleton-text long"></div>
            <div className="skeleton-text medium"></div>
          </div>
          <div className="bottom">
            <div className="skeleton-bar"></div>
            <div className="skeleton-text long"></div>
          </div>
        </div>
        <div className="footer-carousel skeleton">
          <div className="skeleton-text full"></div>
        </div>
      </div>
    );
  }
  
  export default SkeletonTournamentCard