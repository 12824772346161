import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import WinnersCard from "../WinnersCard/WinnersCard";
import Slider from "react-slick";

// TournamentSection.js
export default function WinnersSection({
  item,
  setDetails,
  numOfCards,
  isViewAllActive,
  setIsViewAllActive,
}) {
  const type = item.type;
  item.tournaments = []
  if (!(isViewAllActive === "" || isViewAllActive === type)) return;
  return (
    <section className="TournamentBox">
      <WinnersSectionHead
        type={type}
        isViewAllActive={isViewAllActive}
        setIsViewAllActive={setIsViewAllActive}
      />
      {isViewAllActive ? (
        item.tournaments.length!==0?
        <div className="allCards">
          <div className="cards">
            {item.tournaments.map((tournament, ind) => (
              <WinnersCard
                tournament={tournament}
                key={ind}
                setDetails={setDetails}
              />
            ))}
          </div>
        </div>:<div className="noData">No Entries Yet!</div>
      ) : (
        <Crousel
          tournaments={item.tournaments}
          setDetails={setDetails}
          numOfCards={numOfCards}
        />
      )}
    </section>
  );
}

function WinnersSectionHead({ type, isViewAllActive, setIsViewAllActive }) {
  return (
    <div className="head">
      <div className="type headBtn">{type}</div>
      <button
        className="viewAll headBtn"
        onClick={() => setIsViewAllActive(isViewAllActive === type ? "" : type)}
      >
        {!isViewAllActive ? "View All" : "View Less"}
      </button>
    </div>
  );
}
function Crousel({ tournaments, setDetails, numOfCards }) {
  // const [crouselItemStart, setCrouselItemStart] = useState(1);

  // function handlePrev() {
  //   if (crouselItemStart - numOfCards >= 1)
  //     setCrouselItemStart(crouselItemStart - numOfCards);
  //   else setCrouselItemStart(1);
  // }
  // function handleNext() {
  //   if (crouselItemStart + numOfCards - 1 + numOfCards <= tournaments.length)
  //     setCrouselItemStart(crouselItemStart + numOfCards);
  //   else setCrouselItemStart(tournaments.length - numOfCards + 1);
  // }

  return (
    <div className="crousel">
      {/* <button className="change prev" onClick={handlePrev}>
        <FaChevronLeft />
      </button>
      <div className="crouselItems">
        {tournaments
          .filter(
            (elem, ind) =>
              crouselItemStart <= ind + 1 &&
              ind + 1 < crouselItemStart + numOfCards
          )
          .map((tournament, ind) => (
            <WinnersCard
              key={ind}
              tournament={tournament}
              setDetails={setDetails}
            />
          ))}
      </div>
      <button className="change next" onClick={handleNext}>
        <FaChevronRight />
      </button> */}
      <SliderCompo
        cards={tournaments}
        numOfCards={numOfCards}
        setDetails={setDetails}
      />
    </div>
  );
}

export function SliderCompo({ numOfCards, cards, setDetails }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numOfCards,
    slidesToScroll: numOfCards,
  };
  cards = []

  return (
    <div className="slider-container">
      {cards.length!==0 ?
      <Slider {...settings}>
        {cards.map((tournament, ind) => (
          <WinnersCard
            key={ind}
            tournament={tournament}
            setDetails={setDetails}
          />
        ))}
      </Slider>
      
      :<div className="noData">No Entries Yet!</div>}
    </div>
  );
}
