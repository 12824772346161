import React, { useContext, useEffect, useState } from 'react';
import InputField from './InputField';
import { Link, useNavigate } from 'react-router-dom';
import './AuthCard.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import google from '../../assets/images/Auth/Vector.png';
import password from '../../assets/images/Auth/Solid/Status/password.png';
import { AppContext } from '../../Context/Context';
import { GoogleAuthentication, handleRedirectResult, signInUser } from '../../utils/firebase/SignUp';
import { getUserDetailById } from '../../utils/functions/getUserDetailById';
import { createUser } from '../../utils/functions/createUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const initialLoginValue = { email: "", password: "", rememberMe: false };
const initialSigninValue = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  password: "",
  agreeToTerms: false,
};

export const AuthCard = ({ account, formInput, accountType, setAccount }) => {
  const [isPswd, setPswd] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isSigned, setSignIn, isLoading, setIsLoading, setUserData } = useContext(AppContext);
  const [loginValue, setLoginValue] = useState(initialLoginValue);
  const [signinValue, setSigninValue] = useState(initialSigninValue);
  const [isWaitingForVerification, setIsWaitingForVerification] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [directSignUp, setDirectSignUp] = useState(false)
  const auth = getAuth();
  const navigate = useNavigate();
  const domainUrl = `${process.env.REACT_APP_DOMAIN}`;

  const checkEmailVerification = async () => {
    setIsWaitingForVerification(true);
    try {
      await auth.currentUser.reload();
      if (auth.currentUser?.emailVerified) {
        checkUserInDatabase()
      } else {
        toast.info("Email not yet verified. Please check your email and click the verification link.");
      }
    } catch (error) {
      console.error("Error checking email verification:", error);
      toast.error("Error checking email verification. Please try again.");
    } finally {
      setIsWaitingForVerification(false);
    }
  };

  const validateField = (name, value) => {
    const validations = {
      fname: value => value.length < 3 ? 'Please enter a first name with at least 3 characters' : '',
      lname: value => value.length < 1 ? 'Please enter a last name with at least 3 characters' : '',
      email: value => !/\S+@\S+\.\S+/.test(value) ? 'Please enter a valid email address' : '',
      lichessId: value => value.length < 3 ? 'Lichess ID should be at least 3 characters long' : '',
      phoneNumber: value => {
        const phoneRegex = /^\(?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
        return !phoneRegex.test(value) ? 'Please enter a valid 10-digit phone number' : '';
      },
      password: value => value.length < 6 ? 'Password should be at least 6 characters long' : '',
      confirmPassword: value => value !== (account === 'login' ? loginValue : signinValue).password ? 'Passwords do not match' : '',
      agreeToTerms: value => !value ? 'You must agree to the Terms of Use and Privacy Policy' : '',
    };

    return validations[name] ? validations[name](value) : '';
  };

  const checkUserInDatabase = async () => {
    const url = `${process.env.REACT_APP_BACKEND}/api/v1/createUser`;
    const details = await getUserDetailById(navigate);
    if (details.status === 200) {
      setUserData(details.data);
      navigate('/');
    } else if (details.status === 404) {
      const user = auth.currentUser;
      const uid = user.uid;
      const userDataToStore = { ...signinValue, name: `${signinValue.fname} ${signinValue.lname}`, uid, mobile: signinValue.phone, lichessId: 'test'};
      delete signinValue.fname;
      delete signinValue.lname;
      const headers = {
        'Authorization': 'Bearer ' + user.accessToken,
        'Content-Type': 'application/json'
      };
      try {
        const response = await axios.post(url, userDataToStore, { headers });
        console.log(response)
        if (response.status === 201) {
          console.log("sign in true go to liches")
          setUserData(response.data);
          setDirectSignUp(false)
          setSignIn(true)
        } else {
          toast.error('Error creating user. Please try again.');
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Error creating user. Please try again.');
      }
    } else {
      throw new Error('Bad request. Please check your input.');
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (account === 'signup') {
      setDirectSignUp(true)
    }
    const currentValue = account === 'login' ? loginValue : signinValue;
    const formErrors = Object.keys(currentValue).reduce((acc, key) => {
      const error = validateField(key, currentValue[key]);
      return error ? { ...acc, [key]: error } : acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      const errorMessages = Object.values(formErrors).join('\n');
      toast.error(errorMessages);
    } else if (account === 'signup' && !signinValue.agreeToTerms) {
      toast.error('You must agree to the Terms of Use and Privacy Policy');
    } else {
      try {
        setIsLoading(true);

        const res = account === 'login' ? await signInUser(loginValue) : await createUser(signinValue);
        console.log(res);
        if (res.status === 200) {

          toast.success(account === 'login' ? 'Logged in successfully!' : 'Account created successfully!');
          setSignIn(true);
        } else if (res.status === 700) {
          setVerificationEmailSent(true);
          // toast.info("Please check your email for verification link. You'll be redirected once verified.")
        } else {
          if (res.success === 'false') {
            
            toast.error(res.message);
          } else {
            const errorMessage = res.data.match(/\(([^)]+)\)/)?.[1] || 'An error occurred';
            toast.error(res.data === 'Firebase: Error (auth/email-already-in-use).' ? "An account with this email already exists. Please try logging in." : `Authentication failed: ${errorMessage}`);
          }
        }
      } catch (error) {
        
        toast.error('An unexpected error occurred. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleAccount = () => {
    setLoginValue(initialLoginValue);
    setSigninValue(initialSigninValue);
    setAccount(account === 'login' ? 'signup' : 'login');
    setErrors({});
    setIsSubmitted(false);
  };

  const onValueChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    const setValue = account === 'login' ? setLoginValue : setSigninValue;
    setValue(prev => ({ ...prev, [name]: newValue }));
    if (isSubmitted) {
      setErrors(prev => ({ ...prev, [name]: validateField(name, newValue) }));
    }
  };

  const authenticateGoogleButton = async (e) => {
    e.preventDefault();
    try {
      const res = await GoogleAuthentication();
      if (res.status === 200) {
        toast.success('Successfully authenticated with Google!');
        setSignIn(true);
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      const errorMessage = error.message.match(/\(([^)]+)\)/)?.[1] || 'An error occurred';
      toast.error(error.message === 'Firebase: Error (auth/email-already-in-use).' ? "An account with this email already exists. Please try logging in." : `Google authentication failed: ${errorMessage}`);
    }
  };

  useEffect(() => {
    handleRedirectResult().then(res => {
      if (res && res.status === 200) {
        setSignIn(true);
        setUserData(res.data);
        navigate('/');
        toast.success('Successfully authenticated!');
      }
    });
  }, []);

  useEffect(() => {
    if (directSignUp) {
      // checkEmailVerification()
      console.log("email not verified")
    } else {
      if (isSigned) {
        window.location = `${domainUrl}/lichessauth`;
      }
    }
  }, [isSigned, navigate, directSignUp]);

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {verificationEmailSent
        ? (
          <div className="verification-wrapper">
            <div className="verification-container">
              <p className="verification-message">A verification email has been sent to your email address. Please check your email and click the verification link.</p>
              <button
                className="verification-button"
                onClick={checkEmailVerification}
                disabled={isWaitingForVerification}
              >
                {isWaitingForVerification ? "Checking..." : "I've verified my email"}
              </button>
            </div>
          </div>
        )
        : <form onSubmit={submitHandler} key={account} className='authContainer'>
          <h2>{accountType.heading}</h2>
          {formInput.map((item, index) => (
            <>
              <InputField
                key={index}
                onValueChange={onValueChange}
                initialValue={account === 'login' ? loginValue : signinValue}
                error={isSubmitted ? errors[item.name] : ''}
                {...item}
              />

            </>
          ))}

          <div className='passwordContainer'>
            <input
              required
              onChange={onValueChange}
              name='password'
              value={(account === 'login' ? loginValue : signinValue).password}
              className='password'
              type={!isPswd ? 'password' : 'text'}
              placeholder='Enter Password'
            />
            <div className='iconWrapper1'>
              <img className='pswdIcon1' src={password} alt='password' />
            </div>
            <div className='iconWrapper2' onClick={() => setPswd(prev => !prev)}>
              {isPswd ? <FaEye className='pswdIcon' /> : <FaEyeSlash className='pswdIcon' />}
            </div>
          </div>
          {account === 'signup' && (
            <div className='termsContainer'>
              <input
                type="checkbox"
                id="agreeToTerms"
                name="agreeToTerms"
                checked={signinValue.agreeToTerms}
                onChange={onValueChange}
              />
              <label htmlFor="agreeToTerms">
                I agree to the <a href="https://drive.google.com/file/d/1lWELO16lwVeO6sciZPl9WP3CPycC1UOn/view?usp=sharing" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://drive.google.com/file/d/1nNNTNM6Wus5r-QcfR3p-HatnuTphKJoO/view?usp=sharing" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </label>
              {isSubmitted && errors.agreeToTerms && <span className="error-message">{errors.agreeToTerms}</span>}
            </div>
          )}
          {isSubmitted && errors.password && <span className="error-message">{errors.password}</span>}

          <button disabled={isLoading || (account === 'signup' && !signinValue.agreeToTerms)} type='submit' className='loginButton' >
            {isLoading ? accountType.button.loading : accountType.button.notLoading}
          </button>

          <p style={{ padding: '15px 0', textAlign: 'center', opacity: 0.5, fontFamily: 'Inter' }}>OR</p>
          <button onClick={authenticateGoogleButton} className='googleButton'>
            <img src={google} alt='google' />
            {accountType.googleButton}
          </button>
          <p onClick={toggleAccount} className='lastText'>{accountType.text}</p>
        </form>}
    </>
  );
};