import DropDown from "../DropDown/DropDown";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import WinnersCard from "../WinnersCard/WinnersCard";
import WinnersCardDetails from "../WinnersCardDetails/WinnersCardDetails";

export default function MobileWinners({
  type,
  setType,
  setDetails,
  details,
  width,
  carouselItemsArr,
  types,
}) {
  let tournaments = [];
  for (let i = 0; i < carouselItemsArr.length; i++) {
    if (carouselItemsArr[i].type === type) {
      tournaments = carouselItemsArr[i].tournaments;
      break;
    }
  }

  return (
    <>
      <NavBar />
      <div
        className="mobileTournament"
        style={details ? { display: "none" } : {}}
      >
        <div className="head">
          <span className="sectionTitle">🏆 Winners</span>
          <DropDown type={type} types={types} setType={setType} />
        </div>
        <div className="cards">
          {tournaments.length > 0 ? (
            tournaments.map((tournament, ind) => (
              <WinnersCard
                tournament={tournament}
                key={ind}
                setDetails={setDetails}
              />
            ))
          ) : (
            <p>No Winners Data is Available.</p>
          )}
        </div>
      </div>
      {details && (
        <WinnersCardDetails
          setDetails={setDetails}
          width={width}
          details={details}
        />
      )}
      <Footer />
    </>
  );
}
