import React from 'react'
import './InputField.css'
const InputField = ({onValueChange, placeholder, type, image, name, initialValue, readOnly}) => {
  
  return (
    <div className='inputFieldContainer'>
      <input required readOnly={readOnly} onChange={onValueChange} className='inputType' name={name} value={initialValue[name] || ""} type={type} placeholder={placeholder} />
      <div className='iconWrapper'>
          <img className='InputIcon' src={image} alt='Icon'/>
      </div>
    </div>
  )
}

export default InputField