import { useContext, useEffect, useState } from "react";
import CardDetails from "../CardDetails/CardDetails";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import TournamentSection from "../TournamentSection/TournamentSection";
import { AppContext } from "../../Context/Context";

export default function WebTournament({
  width,
  setDetails,
  types,
  details,
  numOfCards,
  carouselItemsArr,
  isLoading
}) {
  const [isViewAllActive, setIsViewAllActive] = useState("");
  const [registeredTournaments, setRegisteredTournaments] = useState([]);
  const [pastTournaments, setPastTournaments] = useState([]);
const {userData} = useContext(AppContext)
const [user, setUser] = useState(null);


  // Filter tournaments based on their status
  const upcomingTournaments = carouselItemsArr.filter(tournament => tournament.status === "future");
  // const registeredTournaments = carouselItemsArr.filter(tournament => 
  //   tournament.registrants.some(item => item.ctcId === userData.ctcId)
  // );
  useEffect(() => {
    // Set user data when it's available
    // console.log(userData);
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    // Check if the user is already registered
    if (user && carouselItemsArr) {
      const registered = carouselItemsArr?.filter(tournament => tournament?.registrants.some(
        registrant => registrant.ctcId === user.ctcId || registrant.lichessId === user.lichessId
      )   )
      let participated = carouselItemsArr?.filter(tournament=>tournament.status== "past")
      participated = participated.filter(tournament => tournament?.registrants.some(
        registrant => registrant.ctcId === user.ctcId || registrant.lichessId === user.lichessId
      )   )
      setRegisteredTournaments(registered);
      setPastTournaments(participated)
      // console.log(registered);
    }
  }, [user, carouselItemsArr]);  
  // const completedTournaments = carouselItemsArr.filter(tournament => tournament.status === "past");
  const completedTournaments = []

  return (
    <>
      <NavBar />
      <div className="tournament">
        <div
          className="left"
          style={details && width <= 1280 ? { width: 0, height: 0 } : {}}
        >
          {(isViewAllActive === "" || isViewAllActive === "Upcoming") && (
            <TournamentSection
              status="Upcoming"
              width={width}
              cards={upcomingTournaments}
              types={types}
              setDetails={setDetails}
              details={details}
              numOfCards={numOfCards}
              isViewAllActive={isViewAllActive}
              setIsViewAllActive={setIsViewAllActive}
              isLoading={isLoading}
            />
          )}
          {(isViewAllActive === "" || isViewAllActive === "Registered") && (
            <TournamentSection
              status="Registered"
              width={width}
              cards={registeredTournaments}
              types={types}
              setDetails={setDetails}
              details={details}
              numOfCards={numOfCards}
              isViewAllActive={isViewAllActive}
              setIsViewAllActive={setIsViewAllActive}
              isLoading={isLoading}
            />
          )}
          {(isViewAllActive === "" || isViewAllActive === "Completed") && (
            <TournamentSection
              status="Completed"
              width={width}
              cards={pastTournaments}
              types={types}
              setDetails={setDetails}
              details={details}
              numOfCards={numOfCards}
              isViewAllActive={isViewAllActive}
              setIsViewAllActive={setIsViewAllActive}
              isLoading={isLoading}
            />          )}
        </div>
        {details && (
          <CardDetails
            setDetails={setDetails}
            width={width}
            details={details}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
