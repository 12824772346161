import axios from "axios"
import { getAuth } from "firebase/auth";
import signUpUser from "../firebase/SignUp";

const url = `${process.env.REACT_APP_BACKEND}/api/v1/createUser`


export const createUser = async (data) => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(user)

    // if (user === null) {
    const userCreate = await signUpUser(data, user);

    if (userCreate.status === 700) {
        console.log("email not Verified")
        return { status: 700, data: "email not verified" }
    } else {
        console.log("email Verified")
        // console.log(userCreate.data.accessToken)
        try {
            if (userCreate.status === 200) {
                console.log("200")
                return { status: 700, data: userCreate.data }
            }
        } catch (error) {
            console.log(error.message);
            return { status: 500, data: error.message }
        }

    }

}