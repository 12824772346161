import axios from "axios";
import { getAuth } from "firebase/auth";

const url = `${process.env.REACT_APP_BACKEND}/api/v1/updateProfilePicture`;

export const updateProfilePicture = async (FormData) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.log("no user is signed in");
      return { status: 401, data: "No user is signed in" };
    }

    const idToken = await user.getIdToken(true);
    const response = await axios.put(url, FormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + idToken,
      }
    });
    return {status: 200, data: response};
  } catch (error) {
    return {status: 500, data: error.message};
  }
};