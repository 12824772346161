import { useEffect, useLayoutEffect, useState } from "react";
import WebTournament from "../../Component/WebTournament/WebTournament";
import MobileTournament from "../../Component/MobileTournament/MobileTournament";
import "../../tournament_Winners.css";
import { useTournament } from "../../Context/TournamentContext";

const tournamentArr = ["Upcoming", "Registered", "Completed"];
const types = ["bullet", "blitz", "rapid", "classical"];

const Tournament = () => {
  const [status, setStatus] = useState("Upcoming");
  const [type, setType] = useState("blitz");
  const [details, setDetails] = useState(null);
  const [numOfCards, setNumOfCards] = useState(1);
  const [width] = useWindowSize();
  const { 
    tournaments, 
    currentPage,  
    totalPages, 
    isLoading, 
    error, 
    fetchTournaments, 
    nextPage, 
    prevPage 
  } = useTournament();
  
  // useEffect(() => {
  //   fetchTournaments();
  // }, [fetchTournaments]);
  // //(tournaments);
  useEffect(() => {
    if (details) return;
    if (width >= 1680) setNumOfCards(4);
    else if (width >= 1250) setNumOfCards(3);
    else if (width >= 880) setNumOfCards(2);
    else setNumOfCards(2);
  }, [width, details]);

  useEffect(() => {
    if (!details) return;
    if (width >= 1680) setNumOfCards(2);
    else setNumOfCards(1);
  }, [details, width]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }



  if (error) {
    return (
      <div>
        Error: {error}
        <button onClick={() => fetchTournaments()}>Retry</button>
      </div>
    );
  }

  

  if (width <= 880) {
    return (
      <MobileTournament
        status={status}
        tournamentArr={tournamentArr}
        type={type}
        types={types}
        setType={setType}
        setStatus={setStatus}
        setDetails={setDetails}
        details={details}
        width={width}
        carouselItemsArr={tournaments || []}
        carouselItemsArrCompleted={[]} // You might want to update this with completed tournaments data
        isLoading={isLoading}
      />
    );
  }

  return (
    <WebTournament
      width={width}
      setDetails={setDetails}
      types={types}
      details={details}
      numOfCards={numOfCards}
      carouselItemsArr={tournaments || []}
      carouselItemsArrCompleted={[]} 
      isLoading={isLoading}// You might want to update this with completed tournaments data
    />
  );
}

export default Tournament;