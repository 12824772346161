import React from 'react'
import { NavLink } from 'react-router-dom'
import style from '../assets/styles/AdminNav.module.css'


function AdminNav(props) {
  return (
    <div className={style.nav}>
      <div className={style.navup}>
        <div className={style.navupleft}  >CHESS APP</div>
        <div className={style.navuprt}>{props.email}</div>
      </div>
      <div className={style.navdown}>
        <div className={style.navdownlft}>
           {/* <NavLink className={(isActive)}  >Dash bord</NavLink>
           <NavLink className={(isActive)} >Dash bord</NavLink>
           <NavLink className={(isActive)} >Dash bord</NavLink>
           <NavLink className={(isActive)} >Dash bord</NavLink> */}
        </div>
      </div>
    </div>
  )
}

export default AdminNav
