import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../Component/NavBar/NavBar";
import Footer from "../../Component/Footer/Footer";
import { AppContext } from "../../Context/Context";
import HomeContent from "../../Component/HomeContent/HomeContent";
import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce';
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate()
  const { isSigned,setSignIn} = useContext(AppContext)
  const { accessToken, setGoogleSignIn} = useContext(AppContext);
  const url = window.location.href;
  const urlObj = new URL(url);
  const code = urlObj.searchParams.get('code');
  //(code);
  const { open } = useContext(AppContext);
  useEffect(()=>{
    if(accessToken){
      setGoogleSignIn(false);
    //(accessToken)
    }
  },[accessToken])


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <NavBar />
      <div style={{ minHeight: "calc(90vh - 120px)" }}>
        {!open && <HomeContent />}
      </div>
      <div></div>
      <Footer />

    </div>

  );
};

export default Home;
