import React, { useContext, useEffect, useState } from 'react';
import InputField from './InputField';
import { useNavigate } from 'react-router-dom';
import './AuthCard.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import password from '../../assets/images/Auth/Solid/Status/password.png';
import { AppContext } from '../../Context/Context';
import { handleRedirectResult } from '../../utils/firebase/SignUp';
import { createUser } from '../../utils/functions/createUser';
import email from "../../assets/images/Auth/Solid/Communication/email.png";
import user from "../../assets/images/Auth/Solid/Communication/user.png";
import phone from "../../assets/images/Auth/Solid/Communication/Phone.png";
import lincess from "../../assets/images/Auth/Solid/Devices/licenss.png";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailById } from '../../utils/functions/getUserDetailById';
import axios from 'axios';

const initialSigninValue = {
  fname: "",
  lname: "",
  email: "",
  lichessId: "",
  phone: "",
  password: "",
  agreeToTerms: false,
};

const formInput = [
  { image: user, placeholder: "First Name", type: "text", name: "fname" },
  { image: user, placeholder: "Last Name", type: "text", name: "lname" },
  { image: email, placeholder: "Email", type: "email", name: "email" },
  {
    image: lincess,
    placeholder: "Lichess ID",
    type: "text",
    name: "lichessId",
  },
  {
    image: phone,
    placeholder: "Mobile Number",
    type: "phone",
    name: "phone",
  },
];

const accountType = {
  name: "signup",
  button: { notLoading: "Sign Up", loading: "Signing Up..." },
  heading: "Sign Up",
  googleButton: "Continue with Google",
  text: "Already have an Account? Sign In",
};

const UserDetails = () => {
  const [isPswd, setPswd] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setSignIn, isLoading, setIsLoading, setUserData, setGoogleSignIn, lichessId } = useContext(AppContext);
  const [signinValue, setSigninValue] = useState(initialSigninValue);
  const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
  const [isWaitingForVerification, setIsWaitingForVerification] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const account = 'signup';
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setSigninValue(prevState => ({
          ...prevState,
          email: user.email,
          lichessId: lichessId
        }));
        setIsEmailReadOnly(true);
      } else {
        setSigninValue(initialSigninValue);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const checkEmailVerification = async () => {
    setIsWaitingForVerification(true);
    try {
      await auth.currentUser.reload();
      if (auth.currentUser?.emailVerified) {
        checkUserInDatabase()
      } else {
        toast.info("Email not yet verified. Please check your email and click the verification link.");
      }
    } catch (error) {
      console.error("Error checking email verification:", error);
      toast.error("Error checking email verification. Please try again.");
    } finally {
      setIsWaitingForVerification(false);
    }
  };

  const validateField = (name, value) => {
    const validations = {
      fname: value => value.length < 2 ? 'First Name must be at least 2 characters long' : '',
      lname: value => value.length < 1 ? 'Last Name must be at least 1 characters long' : '',
      email: value => !/\S+@\S+\.\S+/.test(value) ? 'Email is invalid' : '',
      lichessId: value => value.length < 2 ? 'Lichess ID must be at least 2 characters long' : '',
      phoneNumber: value => {
        const phoneRegex = /^\(?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
        return !phoneRegex.test(value) ? 'Phone number is invalid' : '';
      },
      password: value => value.length < 6 ? 'Password must be at least 6 characters long' : '',
      confirmPassword: value => value !== signinValue.password ? 'Passwords do not match' : '',
      agreeToTerms: value => !value ? 'You must agree to the Terms of Use and Privacy Policy' : '',
    };

    return validations[name] ? validations[name](value) : '';
  };


  const checkUserInDatabase = async () => {
    const url = `${process.env.REACT_APP_BACKEND}/api/v1/createUser`;
    const details = await getUserDetailById(navigate);
    if (details.status === 200) {
      setUserData(details.data);
      navigate('/');
    } else if (details.status === 404) {
      const user = auth.currentUser;
      const uid = user.uid;
      const userDataToStore = { ...signinValue, name: `${signinValue.fname} ${signinValue.lname}`, uid, mobile: signinValue.phone };
      delete signinValue.fname;
      delete signinValue.lname;
      const headers = {
        'Authorization': 'Bearer ' + user.accessToken,
        'Content-Type': 'application/json'
      };
      try {
        const response = await axios.post(url, userDataToStore, { headers });
        console.log(response);
        if (response.status === 201) {
          setUserData(response.data);
          navigate('/');
        } else {
          toast.error('Error creating user. Please try again.');
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Error creating user. Please try again.');
      }
    } else {
      throw new Error('Bad request. Please check your input.');
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const currentValue = signinValue;
    signinValue.mobile = signinValue.phone
   
    const formErrors = Object.keys(currentValue).reduce((acc, key) => {
      const error = validateField(key, currentValue[key]);
      return error ? { ...acc, [key]: error } : acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      const errorMessages = Object.values(formErrors).join('\n');
      toast.error(errorMessages);
    } else if (!signinValue.agreeToTerms) {
      toast.error('You must agree to the Terms of Use and Privacy Policy');
    } else {
      try {
        setIsLoading(true);

        const res = await createUser(signinValue);
        if (res.status === 200) {
          toast.success('Account created successfully!');
          setSignIn(true);
          setUserData(res.data.user);
          setGoogleSignIn(false);
          navigate('/');
        } else if (res.status === 700) {
          setVerificationEmailSent(true);
          toast.info("Please check your email for verification link. You'll be redirected once verified.")

        } else {
          const errorMessage = res.data.match(/\(([^)]+)\)/)?.[1] || 'An error occurred';
          toast.error(res.data === 'Firebase: Error (auth/email-already-in-use).' ? "An account with this email already exists. Please try logging in." : `Authentication failed: ${errorMessage}`);
        }
      } catch (error) {
        console.error('Authentication error:', error);
        toast.error('An unexpected error occurred. Please try again later.');
      } finally {
        setIsLoading(false);

      }
    }
  };

  const onValueChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setSigninValue(prev => ({ ...prev, [name]: newValue }));
    if (isSubmitted) {
      setErrors(prev => ({ ...prev, [name]: validateField(name, newValue) }));
    }
  };

  useEffect(() => {
    handleRedirectResult().then(res => {
      if (res && res.status === 200) {
        setSignIn(true);
        setUserData(res.data);
        navigate('/');
        toast.success('Successfully authenticated!');
      }
    });
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {verificationEmailSent
        ? (
          <div className="verification-wrapper">
          <div className="verification-container">
            <p className="verification-message">A verification email has been sent to your email address. Please check your email and click the verification link.</p>
            <button
              className="verification-button"
              onClick={checkEmailVerification}
              disabled={isWaitingForVerification}
            >
              {isWaitingForVerification ? "Checking..." : "I've verified my email"}
            </button>
          </div>
          </div>
        )
        : <form onSubmit={submitHandler} key={account} className='authContainer'>
          <h2>{accountType.heading}</h2>
          {formInput.map((item, index) => (
            <InputField
              key={index}
              onValueChange={onValueChange}
              initialValue={signinValue}
              readOnly={(item.name === 'email' || item.name === 'lichessId')&& isEmailReadOnly}
              error={isSubmitted ? errors[item.name] : ''}
              {...item}
            />
          ))}
          <div className='passwordContainer'>
            <input
              required
              onChange={onValueChange}
              name='password'
              value={signinValue.password}
              className='password'
              type={!isPswd ? 'password' : 'text'}
              placeholder='Enter Password'
            />
            <div className='iconWrapper1'>
              <img className='pswdIcon1' src={password} alt='password' />
            </div>
            <div className='iconWrapper2' onClick={() => setPswd(prev => !prev)}>
              {isPswd ? <FaEye className='pswdIcon' /> : <FaEyeSlash className='pswdIcon' />}
            </div>
          </div>
          {isSubmitted && errors.password && <span className="error-message">{errors.password}</span>}
          <div className='termsContainer'>
            <input
              type="checkbox"
              id="agreeToTerms"
              name="agreeToTerms"
              checked={signinValue.agreeToTerms}
              onChange={onValueChange}
            />
            <label htmlFor="agreeToTerms">
              I agree to the <a href="https://drive.google.com/file/d/1lWELO16lwVeO6sciZPl9WP3CPycC1UOn/view?usp=sharing" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://drive.google.com/file/d/1nNNTNM6Wus5r-QcfR3p-HatnuTphKJoO/view?usp=sharing" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </label>
            {isSubmitted && errors.agreeToTerms && <span className="error-message">{errors.agreeToTerms}</span>}
          </div>
          <button disabled={isLoading || !signinValue.agreeToTerms} type='submit' className='loginButton'>
            {isLoading ? accountType.button.loading : accountType.button.notLoading}
          </button>
        </form>
      }

    </>
  );
};

export default UserDetails;
