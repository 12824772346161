// const axios = require("axios");
import axios from "axios";
const url = `${process.env.REACT_APP_BACKEND}/api/v1`;
// const url = `http://127.0.0.1:5001/chesstycoon/us-central1/app/api/v1`;

const axiosCTC = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: "lip_HqVTesqf6MR3rb7ngLoU",
  },
});

axiosCTC.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorObj = {
      status: error.response ? error.response.status : 500,
      message: error.message,
      details: error.response ? error.response.data : "No additional details",
    };
    return Promise.reject(errorObj);
  }
);

export default axiosCTC;
