import React from 'react';
import { RouterProvider } from 'react-router-dom';
import AppProvider from './Context/Context';
import { router } from './routes/routes';
import ComingSoon from "./Coming Soon/src/App"
import TournamentContextProvider from './Context/TournamentContext';
function App() {
  const production = true;
  if(production == true){

    return (
      <AppProvider>
        <TournamentContextProvider>
        <RouterProvider router={router} />

        </TournamentContextProvider>
      </AppProvider>
    );

  }
  else{
    return <>
    <ComingSoon/>
   </>
  }
}

export default App;
